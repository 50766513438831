// import { SiteReadMoreButton } from "./buttons/SiteReadMoreButton";
import { gql } from "@apollo/client";
import classNames from "classnames";
import {
  MangaItemTagWorkContentFragmentDoc,
  TopTagWorksContentPickupTagFragment,
} from "generated/graphql";
import { rr } from "lib/reverseRouter";
import Link from "next/link";
import React, { useCallback, useState } from "react";
import { MediaTopPageLink } from "./buttons/MediaTopPageLink";
import { SiteReadMoreButton } from "./buttons/SiteReadMoreButton";
import { IconTag } from "./elements/IconTag";
import { LoadingIndicator } from "./elements/LoadingIndicator";
import { MangaItemTagWork } from "./elements/MangaItem";
import styles from "./TopTagWorks.module.scss";

gql`
  fragment TopTagWorksContentPickupTag on PickupTag {
    id
    tag {
      id
      title
      works(first: 10) {
        edges {
          node {
            id
            ...MangaItemTagWorkContent
          }
        }
      }
    }
  }

  ${MangaItemTagWorkContentFragmentDoc}
`;

type TopTagWorksProps = {
  pickup: TopTagWorksContentPickupTagFragment | null | undefined;
};

// コンポーネントの内容を表示する最低の作品数
const MIN_DISPLAY_WORKS_COUNT = 5;

export const TopTagWorks: React.FC<TopTagWorksProps> = ({ pickup }) => {
  const [forceDisplayAllItems, setForceDisplayAllItems] = useState(false);
  const onClickDisplayAll = useCallback(() => {
    setForceDisplayAllItems(true);
  }, []);

  if (!pickup) return <LoadingIndicator />;

  // 投稿数が少ない場合は何も表示しない
  if ((pickup.tag.works.edges?.length ?? 0) < MIN_DISPLAY_WORKS_COUNT) {
    return null;
  }

  return (
    <section className={styles.section_container}>
      <div className={styles.wrapper}>
        <div className={styles.headline_container}>
          <h2 className={styles.headline}>
            <span className={styles.icon_tag}>
              <IconTag />
            </span>
            {pickup.tag.title}
          </h2>
          <h3 className={styles.catch}>
            <span>おすすめのタグ</span>
            <img src="/images/top/1f3f7.png" />
          </h3>
        </div>
        <div className={styles.works_list}>
          {pickup.tag.works.edges?.map(({ node: work }) => (
            <div
              className={classNames(styles.manga_item, {
                [styles.force_display]: forceDisplayAllItems,
              })}
              key={work.id}
            >
              <MangaItemTagWork work={work} />
            </div>
          ))}
          {!forceDisplayAllItems && (
            <div className={styles.more_button}>
              <SiteReadMoreButton onClick={onClickDisplayAll} />
            </div>
          )}
        </div>
        <Link {...rr.tags.index({ tagTitle: pickup.tag.title })} legacyBehavior>
          <MediaTopPageLink>{pickup.tag.title}の作品一覧へ</MediaTopPageLink>
        </Link>
      </div>
    </section>
  );
};
