import classNames from "classnames";
import { MangaItemHotSaleEpisodeContentFragment } from "generated/graphql";
import { useCallback, useState } from "react";
import { SiteReadMoreButton } from "./buttons/SiteReadMoreButton";
import { LoadingIndicator } from "./elements/LoadingIndicator";
import { MangaItemHotSaleEpisode } from "./elements/MangaItem";
import styles from "./TopHotSaleEpisodes.module.scss";

interface TopHotSaleEpisodesProps {
  episodes: MangaItemHotSaleEpisodeContentFragment[] | null | undefined;
}

export const TopHotSaleEpisodes: React.FC<TopHotSaleEpisodesProps> = ({
  episodes,
}) => {
  const [forceDisplayAllItems, setForceDisplayAllItems] = useState(false);
  const onClickDisplayAll = useCallback(() => {
    setForceDisplayAllItems(true);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.headline_container}>
        <h2 className={styles.headline}>注目の有料話</h2>
        <h3 className={styles.catch}>
          <span>気分はいつも即売会！</span>
          <img src="/images/top/1f4da.png" />
        </h3>
      </div>
      {episodes ? (
        <ul className={styles.episodes_list}>
          {episodes.map((episode) => {
            return (
              <li
                key={episode.id}
                className={classNames(styles.manga_item, {
                  [styles.force_display]: forceDisplayAllItems,
                })}
              >
                <MangaItemHotSaleEpisode episode={episode} />
              </li>
            );
          })}
          {!forceDisplayAllItems && (
            <SiteReadMoreButton onClick={onClickDisplayAll} />
          )}
        </ul>
      ) : (
        <LoadingIndicator />
      )}
    </div>
  );
};
