import { getUserOrSignInAnonymously } from "lib/firebase";
import {
  isReadMediaSection,
  markMediaSectionAsRead,
} from "lib/readMediaSection";
import { rr } from "lib/reverseRouter";
import Link from "next/link";
import { useCallback, useEffect, useState } from "react";
import { useAsync } from "react-use";
import { CloseButton } from "./buttons/CloseButton";
import { LogoMangaFolio } from "./elements/LogoMangaFolio";
import styles from "./TopPortfolioIntroduction.module.scss";

// トップページのポートフォリオ紹介コーナー
export const TopPortfolioIntroduction: React.FC = () => {
  const { value: user } = useAsync(getUserOrSignInAnonymously);

  // localforageよりポートフォリオ紹介コーナーの閲覧状態を取得する
  const { value: readTopPortfolioIntroSection } = useAsync(async () =>
    isReadMediaSection("TopPortfolioIntro")
  );

  // hideSectionに代入
  const [hideSection, setHideSection] = useState(false);
  useEffect(() => {
    if (typeof readTopPortfolioIntroSection === "undefined") return;
    setHideSection(readTopPortfolioIntroSection);
  }, [readTopPortfolioIntroSection]);

  // click時にlocalforageに記録しつつhideSectionも更新
  const onClick = useCallback(async () => {
    await markMediaSectionAsRead("TopPortfolioIntro");
    setHideSection(true);
  }, []);

  return !hideSection ? (
    <div className={styles.wrapper}>
      <span className={styles.close_container}>
        <CloseButton onClick={onClick} />
      </span>
      <div className={styles.contents}>
        <div className={styles.main}>
          <h2 className={styles.mangafolio_title}>
            <LogoMangaFolio />
          </h2>
          <div className={styles.button_container}>
            <ul className={styles.button_list}>
              {/* userが取れて、匿名でなければ、直接ワークスペースのポートフォリオタブに飛ぶ */}
              {/* Unregisteredなユーザだと /workspace に飛んでしまうかもしれないがそれはそれでOK */}
              <li>
                <a href="/info/mangafolio" className={styles.feature_link}>
                  マンガフォリオとは
                </a>
              </li>
              <li>
                {user && !user.isAnonymous ? (
                  <Link
                    {...rr.workspace.mangafolio.index()}
                    className={styles.start_link}
                  >
                    作ってみる
                  </Link>
                ) : (
                  <Link {...rr.signup.index({})} className={styles.start_link}>
                    無料ではじめる
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.side}>
          <h3>あなたの作品をひとつに集約！</h3>
          <p>
            商業サイトで掲載中の作品や、電子書籍ストアで販売中の作品など、
            <br />
            あなたの作品をポートフォリオにまとめることができます。
          </p>
          <div className={styles.img_container}>
            <span>
              商業サイトで掲載中の作品や、電子書籍ストアで販売中の作品など、あなたの作品をポートフォリオにまとめることができます。
            </span>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
