import { TruncateMultipleLines } from "components/new/ui/TruncateMultipleLines";
import { UserIcon } from "components/new/ui/UserIcon";
import { rr } from "lib/reverseRouter";
import Link from "next/link";
import React from "react";
import styles from "./index.module.scss";

export type AuthorProps = Readonly<{
  displayName: string;
  iconUrl?: string | undefined;
  screenName: string;
}>;

/**
 * 著者情報のコンポーネント。
 * マンガノトップで使用することが多い。
 * 基本的には別窓で開くリンクになる。
 * 同一ウィンドウで開きたい場合は必要になったら調整する。
 * */
export const Author = React.forwardRef<HTMLImageElement, AuthorProps>(
  function Author({ displayName, iconUrl, screenName }, ref) {
    return (
      <Link
        {...rr.site.index({ userScreenName: screenName })}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={styles.author}>
          <UserIcon ref={ref} userIconUrl={iconUrl} size="small" />
          <span className={styles.name}>
            <TruncateMultipleLines lines={2}>
              {displayName}
            </TruncateMultipleLines>
          </span>
        </div>
      </Link>
    );
  }
);
