import classNames from "classnames";
import { ScissorsImage } from "components/ScissorsImage";
import React from "react";
import styles from "./index.module.scss";

export type UserIconProps = Readonly<{
  userIconUrl?: string;
  size: "small" | "large";
  className?: string;
}>;

export const UserIcon = React.forwardRef<HTMLImageElement, UserIconProps>(
  function UserIcon({ userIconUrl, size = "large", className }, ref) {
    if (userIconUrl) {
      return (
        <span className={classNames(className, styles.userIcon, styles[size])}>
          <ScissorsImage
            ref={ref}
            templateUrl={userIconUrl}
            height={160}
            width={160}
          />
        </span>
      );
    } else {
      return (
        <span className={classNames(className, styles.userIcon, styles[size])}>
          <img src="/images/user_icon.svg" />
        </span>
      );
    }
  }
);
