import { gql } from "@apollo/client";
import classNames from "classnames";
import {
  MangaItemRankingWorkContentFragmentDoc,
  TopRankingWorks2ContentFragment,
} from "generated/graphql";
import { rr } from "lib/reverseRouter";
import Link from "next/link";
import React, { useCallback, useState } from "react";
import styles from "./TopRankingWorks.module.scss";
import { MediaTopPageLink } from "./buttons/MediaTopPageLink";
import { SiteReadMoreButton } from "./buttons/SiteReadMoreButton";
import { LoadingIndicator } from "./elements/LoadingIndicator";
import { MangaItemRankingWork } from "./elements/MangaItem";

gql`
  fragment TopRankingWorks2Content on RankingConnection2 {
    edges {
      node {
        id
        ...MangaItemRankingWorkContent
      }
    }
  }
  ${MangaItemRankingWorkContentFragmentDoc}
`;

export type TabName = "Monthly" | "Weekly" | "Daily" | "Total";
interface TopRankingWorksProps2 {
  selectedTabName: TabName;
  works: TopRankingWorks2ContentFragment | undefined;
  shouldDisplayBottomButtons: boolean;
  onTabClick: (tabName: TabName) => void;
}

export const TopRankingWorks2: React.FC<TopRankingWorksProps2> = ({
  selectedTabName,
  works,
  shouldDisplayBottomButtons,
  onTabClick,
}) => {
  const tabClassName = useCallback(
    (tabName: TabName): string | undefined => {
      return tabName === selectedTabName ? styles.current : undefined;
    },
    [selectedTabName]
  );

  const [forceDisplayAllItems, setForceDisplayAllItems] = useState(
    !shouldDisplayBottomButtons // 「もっと見る」ボタンを出さない場合はあらかじめ全件表示
  );
  const onClickDisplayAll = useCallback(() => {
    setForceDisplayAllItems(true);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.contents_header}>
        <div className={styles.headline_container}>
          <h2 className={styles.headline}>作品ランキング</h2>
          <h3 className={styles.catch}>
            <span>人気作品をチェック</span>
            <img src="/images/top/1f451.png" />
          </h3>
        </div>
        <ul className={styles.ranking_tab}>
          <li className={tabClassName("Monthly")}>
            <button
              className={styles.tab_button}
              onClick={() => onTabClick("Monthly")}
            >
              月間
            </button>
          </li>
          <li className={tabClassName("Weekly")}>
            <button
              className={styles.tab_button}
              onClick={() => onTabClick("Weekly")}
            >
              週間
            </button>
          </li>
          <li className={tabClassName("Daily")}>
            <button
              className={styles.tab_button}
              onClick={() => onTabClick("Daily")}
            >
              日間
            </button>
          </li>
          <li className={tabClassName("Total")}>
            <button
              className={styles.tab_button}
              onClick={() => onTabClick("Total")}
            >
              累計
            </button>
          </li>
        </ul>
      </div>
      {works ? (
        <>
          <ul className={styles.works_list}>
            {works?.edges?.map((edge, index) => (
              <li
                key={edge.node.id}
                className={classNames(styles.manga_item, {
                  [styles.force_display]: forceDisplayAllItems,
                })}
              >
                <MangaItemRankingWork
                  work={edge.node}
                  rankingNumber={index + 1}
                />
              </li>
            ))}
          </ul>
          {shouldDisplayBottomButtons && (
            <>
              {!forceDisplayAllItems && (
                <div className={styles.more_button}>
                  <SiteReadMoreButton onClick={onClickDisplayAll} />
                </div>
              )}
              <Link {...rr.rankings.monthly.index()} legacyBehavior>
                <MediaTopPageLink>ランキングページへ</MediaTopPageLink>
              </Link>
            </>
          )}
        </>
      ) : (
        <LoadingIndicator />
      )}
    </div>
  );
};
