import { gql } from "@apollo/client";
import classNames from "classnames";
import { TagListItemLink } from "components/elements/TagListItem";
import { TopHotTagListItemContentFragment } from "generated/graphql";
import { useCarousel } from "hooks/carousel";
import { rr } from "lib/reverseRouter";
import Link from "next/link";
import styles from "./TopHotTagList.module.scss";

type HotTagListProps = {
  tags: TopHotTagListItemContentFragment[] | null | undefined;
};

gql`
  fragment TopHotTagListItemContent on Tag {
    id
    title
  }
`;

/**
 * トップページ「人気のタグ」部分のコンポーネント
 */
export const TopHotTagList: React.FC<HotTagListProps> = ({ tags }) => {
  const { next, prev, canGoToNext, canGoToPrev, isTouch, bond } = useCarousel();
  return (
    <div className={styles.wrapper}>
      <div
        {...bond}
        className={classNames(styles.tag_list, {
          [styles.touch]: isTouch,
        })}
      >
        <div>
          <h3 className={styles.title}>人気のタグ</h3>
        </div>
        {tags?.map((tag) => (
          <div key={tag.id}>
            <Link
              {...rr.tags.index({ tagTitle: tag.title })}
              passHref
              legacyBehavior
            >
              <TagListItemLink>{tag.title}</TagListItemLink>
            </Link>
          </div>
        ))}
      </div>
      <button
        type="button"
        className={styles.button_prev}
        onClick={() => prev({ scrollInterval: "window" })}
        hidden={!canGoToPrev}
      >
        <span>前へ</span>
      </button>
      <button
        type="button"
        className={styles.button_next}
        onClick={() => next({ scrollInterval: "window" })}
        hidden={!canGoToNext}
      >
        <span>次へ</span>
      </button>
    </div>
  );
};
