import classNames from "classnames";
import React from "react";
import styles from "./index.module.scss";

export type TruncateMultipleLinesProps = Readonly<
  {
    children: React.ReactNode;
    lines?: number;
  } & React.ComponentPropsWithRef<"span">
>;

export const TruncateMultipleLines: React.FC<TruncateMultipleLinesProps> = ({
  children,
  lines = 1,
  className,
}) => {
  return (
    <span
      style={{ WebkitLineClamp: lines || "none" }}
      className={classNames(className, styles.truncateMultipleLines)}
    >
      {children}
    </span>
  );
};
TruncateMultipleLines.displayName = "TruncateMultipleLines";
