import dayjs from "dayjs";
import { useNow } from "hooks/now";

export type DateTimeWallProps = Readonly<{
  children: React.ReactNode;
  fallback?: React.ReactNode | undefined;
  from?: string | number | dayjs.Dayjs | Date | undefined;
  to?: string | number | dayjs.Dayjs | Date | undefined;
}>;

/**
 * TimeWall は現在時刻に応じて children を描画するかどうか切り替えるコンポーネント。
 *
 * クライアントサイドだけの実装なので、機密情報をこのコンポーネントで囲っても容易に確認できてしまうことに注意すること。
 */
export const DateTimeWall: React.FC<DateTimeWallProps> = ({
  children,
  fallback = null,
  from,
  to,
}) => {
  const now = useNow();

  // from が渡されていてなおかつ、現在時刻が from よりも前の場合は fallback を返す
  if (from !== undefined && now.isBefore(from)) {
    return <>{fallback}</>;
  }

  // to が渡されていてなおかつ、現在時刻が to よりも後の場合は fallback を返す
  if (to !== undefined && now.isSameOrAfter(to)) {
    return <>{fallback}</>;
  }

  return <>{children}</>;
};
