import React from "react";
import styles from "./MediaTopPageLink.module.scss";

export const MediaTopPageLink = React.forwardRef<
  HTMLAnchorElement,
  JSX.IntrinsicElements["a"]
>(function MediaTopPageLink(props, ref) {
  return (
    <a className={styles.link} ref={ref} {...props}>
      {props.children}
    </a>
  );
});
