/**
 * Media面のセクションを閉じたりするときに使う。
 * 閉じる場所が増えたら Sections を union type にして追加のキーを定義する
 */
import localforage from "localforage";

const READ_MEDIA_SECTION_KEY = "read-media-sections";

export type Sections = "TopPortfolioIntro";

type ReadMediaSectionMap = Record<Sections, boolean | undefined>;

const defaultValue = { TopPortfolioIntro: false };

export async function isReadMediaSection(section: Sections): Promise<boolean> {
  const item =
    (await localforage.getItem<ReadMediaSectionMap>(READ_MEDIA_SECTION_KEY)) ??
    defaultValue;
  return item[section] ?? false;
}

export async function markMediaSectionAsRead(
  section: Sections
): Promise<boolean> {
  const item: ReadMediaSectionMap =
    (await localforage.getItem<ReadMediaSectionMap>(READ_MEDIA_SECTION_KEY)) ??
    defaultValue;
  item[section] = true;
  // 先ほどセットした値なのでデータはあるはず
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return (await localforage.setItem(READ_MEDIA_SECTION_KEY, item))[section]!;
}
