import { TimeWall } from "components/TimeWall";
import dayjs from "dayjs";
import React from "react";
import styles from "./TopMiddleBanner.module.scss";

export const TopMiddleBanner: React.FC = () => {
  return (
    <div className={styles.sub_banner}>
      <TimeWall type="after" day={dayjs("2023-08-31T23:59:59+09:00")}>
        {/* 8/31 23:59 まで表示される */}
        <a
          href="https://lp.comic-copilot.ai/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <picture>
            <source
              srcSet="./images/top/comicopa_sp.png"
              media="(max-width: 600px)"
            />
            <img
              src="./images/top/comicopa_pc.png"
              alt="Comic-Copilot コミコパ 漫画制作サポートAI"
            />
          </picture>
        </a>
      </TimeWall>
      <TimeWall type="before" day={dayjs("2023-09-01T00:00:00+09:00")}>
        <TimeWall type="after" day={dayjs("2023-09-30T23:59:59+09:00")}>
          {/* 2023/9/1 0:00 〜 9/30 23:59 まで表示される*/}
          <a
            href="https://ganmo.j-comi.co.jp/posts?utm_source=manga-no&utm_medium=banner&utm_campaign=hatena_202309"
            target="_blank"
            rel="noopener noreferrer"
          >
            <picture>
              <source
                srcSet="./images/top/ganmo_sp.png"
                media="(max-width: 600px)"
              />
              <img
                src="./images/top/ganmo_pc.png"
                alt="業界最大！登録者1万人以上 漫画のアシスタントしたい、集めたいなら「GANMO」"
              />
            </picture>
          </a>
        </TimeWall>
      </TimeWall>
      <TimeWall type="before" day={dayjs("2023-10-01T00:00:00+09:00")}>
        {/* 10/1 0:00 以降表示される */}
        <a
          href="https://lp.comic-copilot.ai/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <picture>
            <source
              srcSet="./images/top/comicopa_sp.png"
              media="(max-width: 600px)"
            />
            <img
              src="./images/top/comicopa_pc.png"
              alt="Comic-Copilot コミコパ 漫画制作サポートAI"
            />
          </picture>
        </a>
      </TimeWall>
    </div>
  );
};
