import classNames from "classnames";
import React from "react";
import styles from "./TagListItem.module.scss";

type TagListItemLinkProps = React.ComponentPropsWithoutRef<"a">;

export const TagListItemLink = React.forwardRef<
  HTMLAnchorElement,
  TagListItemLinkProps
>(function TagListItemLink({ children, ...props }, ref) {
  return (
    <a className={classNames(styles.tag_item)} ref={ref} {...props}>
      {children}
    </a>
  );
});

type TagListItemButtonProps = React.ComponentPropsWithoutRef<"button"> & {
  selected?: boolean;
};

export const TagListItemButton = React.forwardRef<
  HTMLButtonElement,
  TagListItemButtonProps
>(function TagListItemButton({ children, selected = false, ...props }, ref) {
  return (
    <button
      className={classNames(styles.tag_item, styles.button, {
        [styles.selected]: selected,
      })}
      ref={ref}
      {...props}
    >
      {children}
    </button>
  );
});
