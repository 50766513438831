import classNames from "classnames";
import Link, { LinkProps } from "next/link";
import React from "react";
import styles from "./index.module.scss";

export const TextLinkColor = {
  Bland: "bland",
  Black: "black",
} as const;
export type TextLinkColor = (typeof TextLinkColor)[keyof typeof TextLinkColor];

export const TextLinkDecotarion = {
  Arrow: "arrow",
  Triangle: "triangle",
  Window: "window",
} as const;
export type TextLinkDecotarion =
  (typeof TextLinkDecotarion)[keyof typeof TextLinkDecotarion];

export type TextLinkProps = Readonly<
  Omit<React.ComponentPropsWithoutRef<"a">, "className" | "href"> &
    LinkProps & {
      children: string;
      color?: TextLinkColor | undefined;
      decoration?: TextLinkDecotarion | undefined;
      external?: boolean | undefined;
      height?: number | undefined;
    }
>;

/**
 * テキストによるリンク
 *
 * @example <TextLink {...rr.index()} color={TextLinkColor.Black} decoration={TextLinkDecoration.Triangle}>top</TextLink>
 *
 * @param param.children リンクのラベル
 * @param param.color リンクの色
 * @param param.decoration リンクの装飾
 * @param param.external 外部リンクかどうか、true にすると a タグが使われて、target="_blank" と rel="noopener noreferrer" が付与されて、decoration が triangle になる
 * @param param.height リンクの高さ
 *
 * @returns リンク
 */
export const TextLink: React.FC<TextLinkProps> = ({
  children,
  color = TextLinkColor.Bland,
  decoration: originalDecoration,
  external = false,
  height,
  rel: originalRel,
  target: originalTarget,
  ...props
}) => {
  const linkClassName = classNames(styles.text_link, styles[color]);
  const style: React.CSSProperties = {
    height: height ? `${height}px` : undefined,
    lineHeight: height ? `${height}px` : undefined,
  };

  const decoration = external ? TextLinkDecotarion.Window : originalDecoration;
  const rel = external ? "noopener noreferrer" : originalRel;
  const target = external ? "_blank" : originalTarget;

  return React.createElement(
    external ? "a" : Link,
    {
      className: linkClassName,
      rel: rel,
      style: style,
      target: target,
      ...props,
    },
    <>
      {decoration === TextLinkDecotarion.Arrow ? (
        <span className={styles.icon_arrow} />
      ) : null}
      <span>{children}</span>
      {decoration === TextLinkDecotarion.Triangle ? (
        <span className={styles.icon_triangle} />
      ) : decoration === TextLinkDecotarion.Window ? (
        <span className={styles.icon_window} />
      ) : null}
    </>
  );
};
