import { gql } from "@apollo/client";
import classNames from "classnames";
import {
  PortfolioListItemContentFragmentDoc,
  TopNewPortfoliosContentFragment,
} from "generated/graphql";
import { useCarousel } from "hooks/carousel";
import { rr } from "lib/reverseRouter";
import Link from "next/link";
import React from "react";
import { MediaTopPageLink } from "./buttons/MediaTopPageLink";
import { LoadingIndicator } from "./elements/LoadingIndicator";
import { PortfolioListItem } from "./PortfolioListItem";
import styles from "./TopNewPortfolios.module.scss";

gql`
  fragment TopNewPortfoliosContent on PortfolioConnection {
    edges {
      node {
        id
        ...PortfolioListItemContent
      }
    }
  }
  ${PortfolioListItemContentFragmentDoc}
`;

type TopNewPortfoliosProps = {
  portfolios: TopNewPortfoliosContentFragment | undefined;
};

export const TopNewPortfolios: React.FC<TopNewPortfoliosProps> = ({
  portfolios,
}) => {
  const { next, prev, canGoToNext, canGoToPrev, isTouch, bond } = useCarousel();

  return (
    <div className={styles.wrapper}>
      <div className={styles.headline_container}>
        <h2 className={styles.headline}>新着・更新マンガフォリオ</h2>
      </div>
      {portfolios ? (
        <div className={styles.works_list_container}>
          <ul
            className={classNames(styles.works_list, {
              [styles.touch]: isTouch,
            })}
            {...bond}
          >
            {portfolios.edges?.map((edge) => (
              <li key={edge.node.id} className={styles.manga_item}>
                <PortfolioListItem portfolio={edge.node} />
              </li>
            ))}
          </ul>
          <button
            type="button"
            className={styles.button_prev}
            onClick={() => prev()}
            hidden={!canGoToPrev}
          >
            <span>前へ</span>
          </button>
          <button
            type="button"
            className={styles.button_next}
            onClick={() => next()}
            hidden={!canGoToNext}
          >
            <span>次へ</span>
          </button>
        </div>
      ) : (
        <div>
          <LoadingIndicator />
        </div>
      )}
      <Link {...rr.mangafolio.index()} legacyBehavior>
        <MediaTopPageLink>マンガフォリオページへ</MediaTopPageLink>
      </Link>
    </div>
  );
};
