import styles from "./IconTag.module.scss";

export const IconTag: React.FC = () => (
  <div className={styles.wrapper}>
    <svg viewBox="0 0 32 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.2,27.82h-4.43l.91-10.4h3.52c1.55,0,2.8-1.25,2.8-2.8s-1.25-2.8-2.8-2.8h-3.04l.77-8.78c.13-1.54-1-2.9-2.55-3.03s-2.9,1-3.03,2.55l-.81,9.26h-7.23l.77-8.78C14.21,1.5,13.08,.15,11.53,.01s-2.9,1-3.03,2.55l-.81,9.26H2.8c-1.55,0-2.8,1.25-2.8,2.8H0c0,1.55,1.25,2.8,2.8,2.8H7.2l-.91,10.4H2.8c-1.55,0-2.8,1.25-2.8,2.8s1.25,2.8,2.8,2.8h3l-.73,8.39c-.13,1.54,1,2.9,2.55,3.03s2.9-1,3.03-2.55l.78-8.88h7.23l-.73,8.39c-.13,1.54,1,2.9,2.55,3.03s2.9-1,3.03-2.55l.78-8.88h4.92c1.55,0,2.8-1.25,2.8-2.8h0c0-1.55-1.25-2.8-2.8-2.8Zm-17.29,0l.91-10.4h7.23l-.91,10.4h-7.23Z"
        fill="#000"
        fillOpacity=".3"
      />
    </svg>
  </div>
);
