import { gql } from "@apollo/client";
import classNames from "classnames";
import { LoadingIndicator } from "components/elements/LoadingIndicator";
import {
  MangaItemHotWorkContentFragmentDoc,
  TopHotWorksItemContentFragment,
} from "generated/graphql";
import { useCarousel } from "hooks/carousel";
import { useMemo } from "react";
import { MangaItemHotWork } from "./elements/MangaItem";
import styles from "./TopHotWorks.module.scss";

type TopHotWorksProps = {
  works: TopHotWorksItemContentFragment[] | null | undefined;
};

// トップページ「注目の作品」部分
// firstEpisode aliasを使ってる理由は、内部の MangaItemHotWork の alias と同じ名前にしないとうまくGraphQLがデータを解釈できないため
// このレイヤーではepisodeのstatusを出しわけのために利用している
gql`
  fragment TopHotWorksItemContent on Work {
    id
    firstEpisode: episodes(first: 1) {
      edges {
        node {
          id
          status
        }
      }
    }
    ...MangaItemHotWorkContent
  }

  ${MangaItemHotWorkContentFragmentDoc}
`;

export const TopHotWorks: React.FC<TopHotWorksProps> = ({ works }) => {
  const { next, prev, canGoToNext, canGoToPrev, isTouch, bond } = useCarousel();

  // このコーナーで表示したくない作品を除外
  const filteredWorks = useMemo(
    () =>
      works?.filter((work) => {
        const firstEpisode = work.firstEpisode.edges?.[0].node;
        // 1話目の1ページ目が有料の場合は除外
        if (firstEpisode?.salesInfo?.pagesChargedFrom === 0) {
          return false;
        }
        // 1話目がファン限定話の場合は除外
        if (firstEpisode?.status === "FAN_ONLY") {
          return false;
        }
        return true;
      }),
    [works]
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.headline_container}>
        <h2 className={styles.headline}>注目の作品</h2>
        <h3 className={styles.catch}>
          <span>今 HOTな作品です</span>
          <img src="/images/top/1f4a5.png" />
        </h3>
        <div className={styles.arrow_container}>
          <button
            onClick={() => prev()}
            className={styles.arrow_prev}
            disabled={!canGoToPrev}
          >
            <span>前へ</span>
          </button>
          <button
            onClick={() => next()}
            className={styles.arrow_next}
            disabled={!canGoToNext}
          >
            <span>次へ</span>
          </button>
        </div>
      </div>
      {filteredWorks ? (
        <ul
          className={classNames(styles.list, { [styles.touch]: isTouch })}
          {...bond}
        >
          {filteredWorks.map((work) => (
            <li key={work.id} className={styles.manga_item}>
              <MangaItemHotWork work={work} />
            </li>
          ))}
        </ul>
      ) : (
        <div>
          <LoadingIndicator invert />
        </div>
      )}
    </div>
  );
};
