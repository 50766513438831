import { gql } from "@apollo/client";
import classNames from "classnames";
import { TopPickupCurrentContestWorksContentFragment } from "generated/graphql";
import { rr } from "lib/reverseRouter";
import Link from "next/link";
import React from "react";
import { ScissorsImage } from "../../../ScissorsImage";
import { Author } from "../../organism/Author";
import { Heading } from "../../ui/Heading";
import { TextLink, TextLinkColor, TextLinkDecotarion } from "../../ui/TextLink";
import { TruncateMultipleLines } from "../../ui/TruncateMultipleLines";
import styles from "./TopPickupCurrentContestWorks.module.scss";

gql`
  fragment TopPickupCurrentContestWorksContent on Work {
    id
    user {
      id
      displayName
      screenName
      iconImage {
        id
        url
      }
    }
    title
    isCompleted
    coverImage {
      id
      url
    }
    episodes(first: 0) {
      totalCount
    }
    tags {
      id
      title
    }
  }
`;

export type TopPickupCurrentContestWorksProps = Readonly<{
  works: TopPickupCurrentContestWorksContentFragment[];
}>;

export const TopPickupCurrentContestWorks: React.FC<
  TopPickupCurrentContestWorksProps
> = ({ works }) => {
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.contenst_card}>
          <div className={styles.contenst_card_cover}>
            <Link {...rr.manganoAward2023.index()}>
              <img
                src="/images/award/mangano_award_2023/mangano_award_1st_ogimage.png"
                alt="第1回マンガノ大賞 総勢10編集部が前代未聞の一斉審査！出版社の垣根を越えた最大級の合同マンガ賞"
              />
            </Link>
          </div>
          <div className={styles.contenst_card_info}>
            <div className={styles.contenst_card_meta}>
              <span className={styles.contenst_card_status_label}>募集中</span>
              <span className={styles.contenst_card_award_date}>
                <time>2024/02/15</time>締切
              </span>
            </div>
            <Link {...rr.manganoAward2023.index()}>
              <div className={styles.contenst_sp_hidden}>
                <Heading level={2} appearance={2}>
                  第1回マンガノ大賞
                </Heading>
              </div>
              <div className={styles.contenst_sp_shown}>
                <Heading level={2} appearance={3}>
                  第1回マンガノ大賞
                </Heading>
              </div>
            </Link>
            <div className={styles.contenst_sp_hidden}>
              <p>
                総勢10編集部が一斉審査！出版社の垣根を越えた合同マンガ賞、募集中！
              </p>
              <div className={styles.contenst_card_more_link}>
                <TextLink
                  color={TextLinkColor.Black}
                  decoration={TextLinkDecotarion.Triangle}
                  href="/mangano_award_1st#rules"
                >
                  応募要項を見る
                </TextLink>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.contest_works}>
          <div className={styles.headline_container}>
            <h2>
              <span>PICKUP</span>
            </h2>
            <span className={styles.headline_label}>
              参加作品をランダムに紹介！
            </span>
          </div>
          <ul className={styles.contest_works_list}>
            {works.map((w) => (
              <li key={w.id} className={styles.contest_works_list_item}>
                <Link
                  {...rr.works.index({ workId: w.id })}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className={styles.contest_works_list_item_cover_container}
                  >
                    <ScissorsImage
                      templateUrl={w.coverImage.url}
                      className={styles.contest_works_list_item_cover}
                      width={720}
                      height={405}
                      alt={w.title}
                    />
                  </div>
                </Link>
                <div>
                  <Link
                    {...rr.works.index({ workId: w.id })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h3 className={styles.contest_works_list_item_title}>
                      <TruncateMultipleLines lines={2}>
                        {w.title}
                      </TruncateMultipleLines>
                    </h3>
                  </Link>
                  <Author
                    displayName={w.user.displayName}
                    iconUrl={w.user.iconImage?.url}
                    screenName={w.user.screenName}
                  />
                  <ul className={styles.contest_works_detail_list}>
                    <li>
                      <span>{w.isCompleted ? "完結" : "連載中"}</span>
                    </li>
                    <li>
                      <span>{w.episodes.totalCount}話</span>
                    </li>
                  </ul>
                  <ul className={styles.contest_works_tag_list}>
                    {w.tags?.map((t) => (
                      <li key={t.id}>
                        <span>{t.title}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.contenst_entry_works_link}>
          <Link {...rr.tags.index({ tagTitle: "第1回マンガノ大賞" })}>
            応募作品一覧へ
          </Link>
        </div>
        <div
          className={classNames(
            styles.contenst_sp_shown,
            styles.contenst_direction_sp
          )}
        >
          <p>
            総勢10編集部が一斉審査！出版社の垣根を越えた合同マンガ賞、募集中！
          </p>
          <div className={styles.contenst_card_more_link}>
            <TextLink
              color={TextLinkColor.Black}
              decoration={TextLinkDecotarion.Triangle}
              href="/mangano_award_1st#rules"
            >
              応募要項を見る
            </TextLink>
          </div>
        </div>
      </div>
    </>
  );
};
