// import { SiteReadMoreButton } from "./buttons/SiteReadMoreButton";
import { gql } from "@apollo/client";
import classNames from "classnames";
import {
  MangaItemNewWorkContentFragmentDoc,
  TopNewWorksContentFragment,
} from "generated/graphql";
import { rr } from "lib/reverseRouter";
import Link from "next/link";
import React, { useCallback, useState } from "react";
import { MediaTopPageLink } from "./buttons/MediaTopPageLink";
import { SiteReadMoreButton } from "./buttons/SiteReadMoreButton";
import { LoadingIndicator } from "./elements/LoadingIndicator";
import { MangaItemNewWork } from "./elements/MangaItem";
import styles from "./TopNewWorks.module.scss";

gql`
  fragment TopNewWorksContent on NewWorksConnection {
    edges {
      node {
        id
        ...MangaItemNewWorkContent
      }
    }
  }
  ${MangaItemNewWorkContentFragmentDoc}
`;

type TopNewWorksProps = {
  works: TopNewWorksContentFragment | undefined;
};

export const TopNewWorks: React.FC<TopNewWorksProps> = ({ works }) => {
  const [forceDisplayAllItems, setForceDisplayAllItems] = useState(false);
  const onClickDisplayAll = useCallback(() => {
    setForceDisplayAllItems(true);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.headline_container}>
        <h2 className={styles.headline}>新着・更新作品</h2>
        <h3 className={styles.catch}>
          <span>新作登場</span>
          <img src="/images/top/1f929.png" />
        </h3>
      </div>
      {works ? (
        <>
          <ul className={styles.works_list}>
            {works?.edges?.map(({ node: work }) => (
              <li
                className={classNames(styles.manga_item, {
                  [styles.force_display]: forceDisplayAllItems,
                })}
                key={work.id}
              >
                <MangaItemNewWork work={work} />
              </li>
            ))}
          </ul>
          {!forceDisplayAllItems && (
            <div className={styles.more_button}>
              <SiteReadMoreButton onClick={onClickDisplayAll} />
            </div>
          )}
        </>
      ) : (
        <LoadingIndicator />
      )}
      <Link {...rr.newWorks.index()} legacyBehavior>
        <MediaTopPageLink>新着・更新作品ページへ</MediaTopPageLink>
      </Link>
    </div>
  );
};
