import classNames from "classnames";
import React from "react";
import styles from "./index.module.scss";

export type Level = 1 | 2 | 3 | 4 | 5;
export type TextAlign = "left" | "center" | "right";

export type HeadingProps = Readonly<{
  appearance: Level;
  children: React.ReactNode;
  level?: Level | undefined;
  textAlign?: TextAlign | undefined;
}>;

export const Heading: React.FC<HeadingProps> = ({
  appearance,
  children,
  level = 1,
  textAlign = "left",
}) => {
  return React.createElement(
    `h${level}`,
    {
      className: classNames(
        styles.heading,
        styles[`appearance${appearance ?? level}`],
        styles[textAlign]
      ),
    },
    children
  );
};
