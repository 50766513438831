import { rr } from "lib/reverseRouter";
import Link from "next/link";
import styles from "./TopForGuest.module.scss";

export const TopForGuest: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h2>
          <img
            src="/images/top/headline_guest.svg"
            alt="もっと便利にマンガを読もう！"
          />
        </h2>
        <ul className={styles.list}>
          <li>
            <div className={styles.ic_wrapper}>
              <img src="./images/ic_bookmark.svg" className={styles.icon} />
              <h3>ブックマーク</h3>
            </div>
            <p>
              ブックマークすると、マイページからマンガの更新が確認できるようになります。
            </p>
          </li>
          <li>
            <div className={styles.ic_wrapper}>
              <img src="./images/ic_new.svg" className={styles.icon} />
              <h3>ファン登録</h3>
            </div>
            <p>
              ファン登録をすると、作者の更新情報を追えたり、「ファン限定公開」の作品を読めるようになります。
            </p>
          </li>
          <li>
            <div className={styles.ic_wrapper}>
              <img src="./images/ic_history.svg" className={styles.icon} />
              <h3>閲覧履歴</h3>
            </div>
            <p>
              ログインしていれば、PCやスマホどこから見ても同じ閲覧履歴が見れます。
            </p>
          </li>
        </ul>
        <Link {...rr.signup.index({})} className={styles.btn}>
          登録する<span>無料</span>
        </Link>
      </div>
    </div>
  );
};
