import styles from "./CloseButton.module.scss";

type CloseButtonProps = Omit<
  JSX.IntrinsicElements["button"],
  "children" | "className"
>;

export const CloseButton: React.FC<CloseButtonProps> = (props) => {
  return (
    <button className={styles.close_button} {...props}>
      <svg width="52" height="52" xmlns="http://www.w3.org/2000/svg">
        <title>閉じる</title>
        <g
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="3"
          className={styles.icon}
        >
          <path d="M34 18L18 34M18 18l16 16" />
        </g>
      </svg>
    </button>
  );
};
