import { Dayjs } from "dayjs";
import { useNow } from "hooks/now";

type TimeWallProps = {
  type: "before" | "after";
  day: Dayjs;
  children: React.ReactNode;
};

/**
 * TimeWall は現在時刻に応じて children を描画するかどうか切り替えるコンポーネント。
 *
 * クライアントサイドだけの実装なので、機密情報をこのコンポーネントで囲っても容易に確認できてしまうことに注意すること。
 *
 * @deprecated <DateTimeWall> を使ってください
 */
export const TimeWall: React.FC<TimeWallProps> = ({ type, day, children }) => {
  const now = useNow();

  if (type === "before" && now.isBefore(day)) {
    return null;
  }

  if (type === "after" && now.isAfter(day)) {
    return null;
  }

  return <>{children}</>;
};
