import dayjs, { Dayjs } from "dayjs";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

/**
 * useNow() は現在時刻を返すhook。
 *
 * 本番以外の環境でクエリパラメータに override_requested_at がついている場合はそれを現在時刻として扱う。
 *
 * なお、マンガノ以外の未来時間実装と異なり、現在時刻はクライアントサイドで決定されることに注意すること。
 **/
export function useNow(): Dayjs {
  const [now, setNow] = useState(dayjs());
  const router = useRouter();

  useEffect(() => {
    // 本番では特別対応はしない
    if (process.env.NEXT_PUBLIC_APP_ENV === "production") return;

    // クエリパラメータがセットされていたらそれを現在時刻として扱う
    const overrideRequestedAt = router.query["override_requested_at"] as
      | string
      | undefined;
    if (overrideRequestedAt) {
      setNow(dayjs(overrideRequestedAt));
    }
  }, [router]);

  return now;
}
