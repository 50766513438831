import classNames from "classnames";
import { MangaItemHotNewEpisodeContentFragment } from "generated/graphql";
import { useCallback, useState } from "react";
import { SiteReadMoreButton } from "./buttons/SiteReadMoreButton";
import { LoadingIndicator } from "./elements/LoadingIndicator";
import { MangaItemHotNewEpisode } from "./elements/MangaItem";
import styles from "./TopHotNewEpisodes.module.scss";

interface TopHotNewEpisodesProps {
  episodes: MangaItemHotNewEpisodeContentFragment[] | null | undefined;
}

export const TopHotNewEpisodes: React.FC<TopHotNewEpisodesProps> = ({
  episodes,
}) => {
  const [forceDisplayAllItems, setForceDisplayAllItems] = useState(false);
  const onClickDisplayAll = useCallback(() => {
    setForceDisplayAllItems(true);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.headline_container}>
        <h2 className={styles.headline}>注目の無料話</h2>
        <h3 className={styles.catch}>
          <span>この話いいね</span>
          <img src="/images/top/1f44d.png" />
        </h3>
      </div>
      {episodes ? (
        <>
          <ul className={styles.episodes_list}>
            {episodes?.map((episode) => {
              return (
                <li
                  key={episode.id}
                  className={classNames(styles.manga_item, {
                    [styles.force_display]: forceDisplayAllItems,
                  })}
                >
                  <MangaItemHotNewEpisode episode={episode} />
                </li>
              );
            })}
          </ul>
          {!forceDisplayAllItems && (
            <SiteReadMoreButton onClick={onClickDisplayAll} />
          )}
        </>
      ) : (
        <LoadingIndicator />
      )}
    </div>
  );
};
