import { gql } from "@apollo/client";
import { LoadingIndicator } from "components/elements/LoadingIndicator";
import {
  TopInformationBannerFragment,
  TopInformationNewsEntryFragment,
} from "generated/graphql";
import { formatDateForTimeElement, formatDateShort } from "lib/formatter";
import styles from "./TopInformation.module.scss";

type TopInformationProps = {
  infoEntries: TopInformationNewsEntryFragment[] | null | undefined;
  banner: TopInformationBannerFragment | null | undefined;
};

export const TopInformation: React.FC<TopInformationProps> = ({
  infoEntries,
  banner,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {/* デザイナー向け：バナー更新方法について https://box.hatena.sh/manga-toukou/マンガノ_バナー作成・更新方法について */}
        <section className={styles.banner_section}>
          <div id="gtm-banner">
            {/* 掲載可能なバナーが存在しない可能性に注意する */}
            {banner && <TopInformationBanner banner={banner} />}
          </div>
        </section>
        <section className={styles.news_section}>
          {infoEntries ? (
            <ul className={styles.news_list}>
              {infoEntries?.map((infoEntry) => (
                <TopInformationNewsEntry
                  key={infoEntry.id}
                  infoEntry={infoEntry}
                />
              ))}
            </ul>
          ) : (
            <LoadingIndicator />
          )}
        </section>
      </div>
    </div>
  );
};

gql`
  fragment TopInformationBanner on Banner {
    id
    pcImageURL
    spImageURL
    url
    alt
  }
`;

type TopInformationBannerProps = {
  banner: TopInformationBannerFragment;
};

const TopInformationBanner: React.FC<TopInformationBannerProps> = ({
  banner: { pcImageURL, spImageURL, url, alt },
}) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <picture>
        <source srcSet={spImageURL} media="(max-width: 600px)" />
        <img src={pcImageURL} alt={alt} />
      </picture>
    </a>
  );
};

gql`
  fragment TopInformationNewsEntry on InfoEntry {
    id
    title
    category
    publishedAt
    link
  }
`;

type TopInformationNewsEntryProps = {
  infoEntry: TopInformationNewsEntryFragment;
};

const TopInformationNewsEntry: React.FC<TopInformationNewsEntryProps> = ({
  infoEntry: { title, category, publishedAt, link },
}) => {
  const url = new URL(link);
  url.searchParams.set("utm_source", "manga-no"); // マンガノからのリンクであることを表明する
  url.searchParams.append("utm_content", "TopInformation");

  return (
    <li>
      <a href={url.href} target="_blank" rel="noopener noreferrer">
        <span className={styles.category}>{category}</span>
        <time dateTime={formatDateForTimeElement(publishedAt)}>
          {formatDateShort(publishedAt)}
        </time>
        <span className={styles.title}>{title}</span>
      </a>
    </li>
  );
};
