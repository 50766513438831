import { gql, NormalizedCacheObject } from "@apollo/client";
import classNames from "classnames";
import { Footer } from "components/Footer";
import { Head } from "components/Head";
import { TopPickupCurrentContestWorks } from "components/new/templates/TopPickupCurrentContestWorks";
import { DateTimeWall } from "components/new/utils/DateTimeWall";
import { SettingHeader } from "components/SettingHeader";
import { TopForCreator } from "components/TopForCreator";
import { TopForGuest } from "components/TopForGuest";
import { TopHotNewEpisodes } from "components/TopHotNewEpisodes";
import { TopHotSaleEpisodes } from "components/TopHotSaleEpisodes";
import { TopHotTagList } from "components/TopHotTagList";
import { TopHotWorks } from "components/TopHotWorks";
import { TopInformation } from "components/TopInformation";
import { TopMiddleBanner } from "components/TopMiddleBanner";
import { TopNewPortfolios } from "components/TopNewPortfolios";
import { TopNewWorks } from "components/TopNewWorks";
import { TopOdaiWorks } from "components/TopOdaiWorks";
import { TopPortfolioIntroduction } from "components/TopPortfolioIntroduction";
import { TabName, TopRankingWorks2 } from "components/TopRankingWorks2";
import { TopTagWorks } from "components/TopTagWorks";
import { GACampaignParamsProvider } from "contexts/gaCampaign";
import dayjs from "dayjs";
import {
  MangaItemHotNewEpisodeContentFragmentDoc,
  MangaItemHotSaleEpisodeContentFragmentDoc,
  MediaTopDocument,
  MediaTopQuery,
  MediaTopQueryVariables,
  PriceDisplayContentFragmentDoc,
  TopHotTagListItemContentFragmentDoc,
  TopHotWorksItemContentFragmentDoc,
  TopInformationBannerFragmentDoc,
  TopInformationNewsEntryFragmentDoc,
  TopNewPortfoliosContentFragmentDoc,
  TopNewWorksContentFragmentDoc,
  TopPickupCurrentContestWorksContentFragmentDoc,
  TopRankingWorks2ContentFragment,
  TopRankingWorks2ContentFragmentDoc,
  TopTagWorksContentPickupTagFragmentDoc,
  TopTagWorksContentThemeFragmentDoc,
  useMediaTopClientOnlyLazyQuery,
  useMediaTopQuery,
} from "generated/graphql";
import { initializeApollo } from "lib/apolloClient";
import { getUserOrSignInAnonymously } from "lib/firebase";
import { GetStaticProps, NextPage } from "next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAsync } from "react-use";
import styles from "./index.module.scss";

gql`
  query MediaTop {
    hotWorks {
      ...TopHotWorksItemContent
    }
    hotNewEpisodes {
      ...MangaItemHotNewEpisodeContent
    }
    hotTags {
      ...TopHotTagListItemContent
    }
    banner {
      ...TopInformationBanner
    }
    infoEntries {
      ...TopInformationNewsEntry
    }
    sellingNewEpisodes {
      ...MangaItemHotSaleEpisodeContent
    }
    ranking {
      monthly2(first: 10) {
        ...TopRankingWorks2Content
      }
      weekly2(first: 10) {
        ...TopRankingWorks2Content
      }
      daily2(first: 10) {
        ...TopRankingWorks2Content
      }
      total2(first: 10) {
        ...TopRankingWorks2Content
      }
    }
    newWorks2(first: 15) {
      ...TopNewWorksContent
    }
    portfolios(first: 8) {
      ...TopNewPortfoliosContent
    }
    themes {
      ...TopTagWorksContentTheme
    }
    pickupTags {
      ...TopTagWorksContentPickupTag
    }
    pickupCurrentContestWorks {
      ...TopPickupCurrentContestWorksContent
    }
  }

  # クライアントサイドでLazyに読み込むクエリ
  query MediaTopClientOnly {
    # 購入に関わる部分はクライアントで再取得する
    sellingNewEpisodes {
      ...PriceDisplayContent
    }
  }

  ${MangaItemHotNewEpisodeContentFragmentDoc}
  ${MangaItemHotSaleEpisodeContentFragmentDoc}
  ${TopHotWorksItemContentFragmentDoc}
  ${TopInformationBannerFragmentDoc}
  ${TopInformationNewsEntryFragmentDoc}
  ${TopRankingWorks2ContentFragmentDoc}
  ${TopNewWorksContentFragmentDoc}
  ${PriceDisplayContentFragmentDoc}
  ${TopNewPortfoliosContentFragmentDoc}
  ${TopHotTagListItemContentFragmentDoc}
  ${TopTagWorksContentThemeFragmentDoc}
  ${TopTagWorksContentPickupTagFragmentDoc}
  ${TopPickupCurrentContestWorksContentFragmentDoc}
`;

// お題を表示するのに必要な投稿件数
const REQUIRED_TO_DISPLAY_THEME_ITEMS = 5;

const Index: NextPage = () => {
  const mediaTop = useMediaTopQuery({
    fetchPolicy: "cache-first",
  });

  const ranking = mediaTop.data?.ranking;

  const [selectedTab, setSelectedTab] = useState<TabName>("Weekly");
  const handleTabClick = useCallback((tabName: TabName) => {
    setSelectedTab(tabName);
  }, []);

  const rankingWorks = useMemo<
    TopRankingWorks2ContentFragment | undefined
  >(() => {
    switch (selectedTab) {
      case "Monthly":
        return ranking?.monthly2;
      case "Weekly":
        return ranking?.weekly2;
      case "Daily":
        return ranking?.daily2;
      case "Total":
        return ranking?.total2;
    }
  }, [
    ranking?.daily2,
    ranking?.weekly2,
    ranking?.monthly2,
    ranking?.total2,
    selectedTab,
  ]);

  // 投稿件数が少ないお題は出さない
  const themes = mediaTop.data?.themes?.filter(
    (t) => REQUIRED_TO_DISPLAY_THEME_ITEMS <= (t.tag.works.edges?.length ?? 0)
  );

  const { value: user } = useAsync(getUserOrSignInAnonymously);

  // 購入にまつわる部分はクライアントサイドで再取得する
  const [mediaTopClientOnlyLazy] = useMediaTopClientOnlyLazyQuery({
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (typeof window === "undefined") return;
    if (!mediaTop.data) return;
    mediaTopClientOnlyLazy();
  }, [mediaTop, mediaTop.data, mediaTopClientOnlyLazy]);

  return (
    <>
      <Head
        title="マンガノ - 新しいマンガ投稿サイト"
        description="SNSで活動するマンガ家、同人活動を中心に個人で活動するマンガ家、プロを目指して創作を続けるマンガ家、あらゆるマンガ家の発信基地となるツール「マンガノ」です。"
        ogImage="/images/og_image.png"
      />
      <div>
        <SettingHeader type="top" />
        <TopInformation
          infoEntries={mediaTop.data?.infoEntries}
          banner={mediaTop.data?.banner}
        />
      </div>
      <div className={styles.main_contents}>
        <section className={styles.top_container}>
          <GACampaignParamsProvider utmContent="TopHotWorks">
            <TopHotWorks works={mediaTop.data?.hotWorks} />
          </GACampaignParamsProvider>
        </section>
        <section>
          <TopHotTagList tags={mediaTop.data?.hotTags} />
        </section>
        <DateTimeWall
          fallback={
            <section>
              <TopPortfolioIntroduction />
            </section>
          }
          to={dayjs("2024-02-16T11:00:00+09:00")}
        >
          {mediaTop.data?.pickupCurrentContestWorks && (
            <section
              className={classNames(
                styles.section_container,
                styles.current_contest_section
              )}
            >
              <TopPickupCurrentContestWorks
                works={mediaTop.data.pickupCurrentContestWorks}
              />
            </section>
          )}
        </DateTimeWall>
        <section
          className={classNames(
            styles.section_container,
            styles.mangafolio_section
          )}
        >
          <TopNewPortfolios portfolios={mediaTop.data?.portfolios} />
        </section>
        <section className={styles.section_container}>
          <GACampaignParamsProvider utmContent="TopRankingWorks">
            <TopRankingWorks2
              selectedTabName={selectedTab}
              works={rankingWorks}
              shouldDisplayBottomButtons={true}
              onTabClick={handleTabClick}
            />
          </GACampaignParamsProvider>
        </section>
        <section className={styles.section_container}>
          <GACampaignParamsProvider utmContent="TopNewWorks">
            <TopNewWorks works={mediaTop.data?.newWorks2} />
          </GACampaignParamsProvider>
        </section>
        <GACampaignParamsProvider utmContent="TopPickupTagWorks">
          {mediaTop.data?.pickupTags?.map((pickup) => (
            <TopTagWorks key={pickup.id} pickup={pickup} />
          ))}
        </GACampaignParamsProvider>
        <GACampaignParamsProvider utmContent="TopThemeWorks">
          {themes?.map((theme, i) => (
            // 1つ目のお題だけ「今月のお題」画像を表示する
            <TopOdaiWorks key={theme.id} theme={theme} showHeadline={i === 0} />
          ))}
        </GACampaignParamsProvider>
        <section className={styles.sub_banner_container}>
          <TopMiddleBanner />
        </section>
        <section className={styles.section_container}>
          <GACampaignParamsProvider utmContent="TopHotSaleEpisodes">
            <TopHotSaleEpisodes episodes={mediaTop.data?.sellingNewEpisodes} />
          </GACampaignParamsProvider>
        </section>
        <section className={styles.message}>
          <a href="/creator#point2">
            <img
              src="./images/logo_black_cutter.svg"
              alt="マンガノ"
              className={styles.logo_image}
            />
            <span>
              <strong>イベント</strong>や<strong>電子書店</strong>
              で販売した作品を
              <br />
              マンガノで販売してみませんか?
            </span>
          </a>
        </section>
        <section className={styles.section_container}>
          <GACampaignParamsProvider utmContent="TopHotNewEpisodes">
            <TopHotNewEpisodes episodes={mediaTop.data?.hotNewEpisodes} />
          </GACampaignParamsProvider>
        </section>
      </div>
      <div>
        {user && user.isAnonymous && <TopForGuest />}
        <TopForCreator />
      </div>
      <div>
        <Footer />
      </div>
      <div data-next-public-app-env={process.env.NEXT_PUBLIC_APP_ENV}></div>
    </>
  );
};

export default Index;

export const getStaticProps: GetStaticProps<{
  initialApolloState: NormalizedCacheObject;
}> = async () => {
  const apolloClient = initializeApollo();

  await apolloClient.query<MediaTopQuery, MediaTopQueryVariables>({
    query: MediaTopDocument,
    fetchPolicy: "cache-first",
  });

  const initialApolloState = apolloClient.cache.extract();

  return {
    props: {
      initialApolloState: initialApolloState,
    },
    // 1分おきに更新
    revalidate: 60,
  };
};
