import { gql } from "@apollo/client";
import classNames from "classnames";
import dayjs from "dayjs";
import {
  MangaItemTagWorkContentFragmentDoc,
  TopTagWorksContentThemeFragment,
} from "generated/graphql";
import { rr } from "lib/reverseRouter";
import Link from "next/link";
import React, { useCallback, useState } from "react";
import { MediaTopPageLink } from "./buttons/MediaTopPageLink";
import { SiteReadMoreButton } from "./buttons/SiteReadMoreButton";
import { IconTag } from "./elements/IconTag";
import { LoadingIndicator } from "./elements/LoadingIndicator";
import { MangaItemTagWork } from "./elements/MangaItem";
import styles from "./TopOdaiWorks.module.scss";

gql`
  fragment TopTagWorksContentTheme on Theme {
    id
    startsAt
    detailUrl
    tag {
      id
      title
      works(first: 10) {
        edges {
          node {
            id
            ...MangaItemTagWorkContent
          }
        }
      }
    }
  }

  ${MangaItemTagWorkContentFragmentDoc}
`;

type TopOdaiWorksProps = {
  theme: TopTagWorksContentThemeFragment | null | undefined;
  showHeadline: boolean;
};

export const TopOdaiWorks: React.FC<TopOdaiWorksProps> = ({
  theme,
  showHeadline,
}) => {
  const [forceDisplayAllItems, setForceDisplayAllItems] = useState(false);
  const onClickDisplayAll = useCallback(() => {
    setForceDisplayAllItems(true);
  }, []);

  if (!theme) return <LoadingIndicator />;

  return (
    <section className={styles.section_container}>
      {showHeadline && (
        <div className={styles.headline_odai}>
          <img
            src="/images/top/headline_odai.svg"
            alt="タグをつけて参加 今月のお題"
          />
          <p>
            <strong>{dayjs(theme.startsAt).tz().month() + 1}</strong>月
          </p>
        </div>
      )}
      <div className={styles.wrapper}>
        <div className={styles.headline_container}>
          <h2 className={styles.headline}>
            <span className={styles.icon_tag}>
              <IconTag />
            </span>
            {theme.tag.title}
          </h2>
          <a
            href={theme.detailUrl}
            className={styles.entry_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            お題に参加してみる
          </a>
        </div>
        <div className={styles.works_list}>
          {theme.tag.works.edges?.map(({ node: work }) => (
            <div
              className={classNames(styles.manga_item, {
                [styles.force_display]: forceDisplayAllItems,
              })}
              key={work.id}
            >
              <img
                className={styles.ic_odai}
                src="/images/top/ic_odai.svg"
                alt="お題"
              />
              <MangaItemTagWork work={work} />
            </div>
          ))}
          {!forceDisplayAllItems && (
            <div className={styles.more_button}>
              <SiteReadMoreButton onClick={onClickDisplayAll} />
            </div>
          )}
        </div>
        <Link {...rr.tags.index({ tagTitle: theme.tag.title })} legacyBehavior>
          <MediaTopPageLink>このお題の作品一覧へ</MediaTopPageLink>
        </Link>
      </div>
    </section>
  );
};
