import { rr } from "lib/reverseRouter";
import Link from "next/link";
import styles from "./TopForCreator.module.scss";

export const TopForCreator: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.catch}>for creators</div>
        <h2>
          <img
            src="/images/top/headline_creator.svg"
            alt="「マンガノ」でマンガを公開しよう！"
          />
        </h2>
        <ul className={styles.list}>
          <li>
            あなたの全てのマンガを<strong>自由に公開</strong>できます
          </li>
          <li>
            あなたのマンガを<strong>自由に販売</strong>できます
          </li>
          <li>
            ポジティブなコメントだけが届く<strong>やわらかコメント機能</strong>
            もあります
          </li>
        </ul>
        <Link {...rr.creator.index()} className={styles.btn}>
          詳しくはこちら
        </Link>
      </div>
    </div>
  );
};
